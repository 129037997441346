import React from 'react';
import { IsoType } from '@spa-cars/ui';
import { motion } from 'framer-motion';
import logo from '../../../public/images/logo.png';
import Steps from './Steps';
import StepsCarousel from './StepsCarousel';
import { experienceSteps } from '../../../lib/experienceSteps';

const experienceVariant = {
  initial: {
    opacity: 0,
  },
  inView: {
    opacity: [0, 1],
    transition: {
      duration: 1,
    },
  },
};

function Experience() {
  const [activeStep, setActiveStep] = React.useState(0);

  return (
    <motion.div
      id="experience"
      className="relative w-full m-auto pl-5 md:pl-20 flex flex-col mt-36 pb-10 overflow-hidden"
      variants={experienceVariant}
      whileInView="inView"
      initial="initial"
    >
      <div className="absolute overflow-hidden -top-10">
        <IsoType className="relative opacity-5 w-[30vw] md:w-[50vw] h-auto" />
      </div>
      <div className=" flex items-center w-full">
        <p className="text-text-black text-2xl md:text-4xl font-semibold">
          Vive la experiencia
        </p>
        <img
          className="w-44 md:w-52 h-auto mt-1 ml-5 z-0"
          src={logo.src}
          alt="logo"
        />
      </div>
      <div className="w-full flex mt-10 relative z-10">
        <div className="w-1/3 hidden md:block">
          <Steps
            steps={experienceSteps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </div>
        <div className="w-full md:w-2/3 flex flex-col">
          <StepsCarousel
            steps={experienceSteps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default Experience;
