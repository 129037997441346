import React from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import test from '../../../public/images/shop-1.jpg';

const cardInfoVariant = {
  initial: {
    y: 100,
    opacity: 1,
  },
  inView: {
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

interface CardInfoProps {
  title: {
    title: string;
    description: string;
    year: string;
  };
}

function CardInfo({ title }: CardInfoProps) {
  const cardControls = useAnimationControls();
  React.useEffect(() => {
    cardControls.start({
      opacity: [0, 1],
      y: [1000, 0],

      transition: {
        duration: 1.5,
      },
    });
  }, [title]);

  return (
    <motion.div
      variants={cardInfoVariant}
      whileInView="inView"
      initial="initial"
      className="relative z-30"
      animate={cardControls}
    >
      <div className="absolute cursor-pointer left-0 right-0 mx-auto top-[-60px] z-30 w-[20rem] h-[22rem] rounded-xl shadow-lg bg-white flex  justify-center}">
        <img
          src={test.src}
          alt=""
          className="absolute h-full object-cover rounded-xl"
        />
        <div className="absolute w-full h-full bg-primary-500 opacity-75 rounded-xl" />
        <div className="relative z-10 flex flex-col text-center items-center w-full py-10 px-5">
          <span className="mb-2 text-text-white font-semibold text-xl">
            {title.title}
          </span>
          <hr className="bg-white w-full" />
          <span className="text-text-white  mt-2">{title.description}</span>
        </div>
      </div>
      {/* right subcards */}
      <div className="absolute  rotate-[9deg] cursor-pointer left-[60px] right-0 mx-auto top-[-25px] z-10 w-[20rem] h-[22rem] rounded-xl shadow-lg bg-white flex  justify-center">
        <img
          src={test.src}
          alt=""
          className="absolute h-full object-cover rounded-xl"
        />
      </div>
      <div className="absolute rotate-[20deg] cursor-pointer left-[75px] right-0 mx-auto top-[60px] z-20 w-[20rem] h-[22rem] rounded-xl shadow-lg bg-white flex  justify-center">
        <img
          src={test.src}
          alt=""
          className="absolute h-full object-cover rounded-xl"
        />
      </div>
      {/* left subcards */}
      <div className="absolute  rotate-[-9deg] cursor-pointer right-[60px] left-0 mx-auto top-[-25px] z-10 w-[20rem] h-[22rem] rounded-xl shadow-lg bg-white flex  justify-center}">
        <img
          src={test.src}
          alt=""
          className="absolute h-full object-cover rounded-xl"
        />
      </div>
      <div className="absolute rotate-[-20deg] cursor-pointer right-[75px] left-0 mx-auto top-[60px] z-20 w-[20rem] h-[22rem] rounded-xl shadow-lg bg-white flex  justify-center}">
        <img
          src={test.src}
          alt=""
          className="absolute h-full object-cover rounded-xl"
        />
      </div>
    </motion.div>
  );
}

export default CardInfo;
