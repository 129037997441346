import React from 'react';
import { IsoType } from '@spa-cars/ui';
import logo from '../../../public/images/logo.png';
import carShadow from '../../../public/images/car-shadow.png';
import Mosaic from './Mosaic';
import CardInfo from './CardInfo';
import Titles from './Titles';

const titles = [
  {
    title: 'Oct 2021',
    description: 'Gestación de la idea',
    year: 'Oct 2021',
  },
  {
    title: 'Mar 2022',
    description:
      'Alianza acelearadora Wave Tech Hub. I & D del modelo de negocio y diseño de la APP y la cabina',
    year: 'Mar 2022',
  },
  {
    title: 'Abr 2022',
    description: 'Desarrollo del Pitch Deck',
    year: 'Abr 2022',
  },
  {
    title: 'Jul 2022',
    description: 'Levantamiento de capital, Angel Investment',
    year: '2020',
  },
  {
    title: 'Nov 2022',
    description: 'Lanzamiento de Spacars, una sucursal con dos cabinas',
    year: 'Nov 2022',
  },
];

function History() {
  const [activeTitle, setActiveTitle] = React.useState(0);

  return (
    <div id="history" className=" relative overflow-hidden">
      <article className="max-w-[1390px] m-auto px-5 bg-white py-10  h-auto overflow-hidden relative ">
        <div className="flex flex-col sm:flex-row justify-center items-center mb-10">
          <h3 className="text-text-black text-2xl md:text-4xl font-semibold ">
            Antes y después de
          </h3>
          <img
            className="w-44 md:w-52 h-auto mt-1 ml-5 z-0"
            src={logo.src}
            alt="logo"
          />
        </div>
        <Mosaic
          title={titles[activeTitle]}
          titles={titles}
          activeTitle={activeTitle}
          setActiveTitle={setActiveTitle}
        />
        <div className="hidden sm:block relative h-5 sm:h-48">
          <CardInfo title={titles[activeTitle]} />
        </div>
        <Titles
          titles={titles}
          activeTitle={activeTitle}
          setActiveTitle={setActiveTitle}
        />
      </article>
      {/* water marks */}
      <IsoType className="opacity-10 absolute z-0 left-[-300px] top-[-550px] mt-10 w-[20rem] h-[18rem] md:w-[45rem] md:h-[60rem]" />
      <IsoType className="rotate-180 opacity-10 absolute z-0 right-[-300px] bottom-[-550px] mt-10 w-[20rem] h-[18rem] md:w-[45rem] md:h-[60rem]" />
      <img
        src={carShadow.src}
        alt="car"
        className=" absolute z-0 left-0 top-0 bottom-0 mx-auto my-auto right-0 w-[20rem] h-auto md:w-[80rem] md:h-auto"
      />
    </div>
  );
}

export default History;
