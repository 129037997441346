import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { AnimatePresence, motion } from 'framer-motion';
import { SignInModal, SignUpModal } from '../auth';
import ResetPasswordModal from '../auth/ResetPasswordModal';
import ChangePasswordModal from '../auth/ChangePasswordModal';
import logo from '../../public/images/logo.png';
import logoLight from '../../public/images/logo-light.png';

const logoVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: [0, 1],
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
  exit: {
    opacity: [1, 0],
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
};

interface NavProps {
  startNavAnimations: boolean;
  scrollToSection?: (idx: number) => any;
}

function Nav({ startNavAnimations, scrollToSection }: NavProps) {
  const router = useRouter();

  const [signInModalIsOpen, setSignInModalIsOpen] = React.useState(false);
  const [signUpModalIsOpen, setSignUpModalIsOpen] = React.useState(false);
  const [resetPasswordModalIsOpen, setResetPasswordModalIsOpen] =
    React.useState(false);
  const [changePasswordModalIsOpen, setChangePasswordModalIsOpen] =
    React.useState(false);

  React.useEffect(() => {
    if (router.pathname.includes('reset-password')) {
      setChangePasswordModalIsOpen(true);
    }
  }, []);

  return (
    <>
      <nav
        className={`w-full absolute z-50 bg-transparent flex  px-5 sm:px-10 py-5 overflow-hidden md:overflow-visible `}
      >
        {/* logo */}
        <AnimatePresence>
          {!startNavAnimations ? (
            <motion.div
              className="w-36 md:w-[14rem]  pt-2"
              variants={logoVariant}
              exit="exit"
            >
              <Image
                src={logo.src}
                alt="Spacars logo"
                height={50}
                width={250}
              />
            </motion.div>
          ) : (
            <motion.div
              className="w-36 md:w-[14rem] pt-2 "
              variants={logoVariant}
              initial="initial"
              animate="animate"
            >
              <Image
                src={logoLight.src}
                alt="Spacars logo"
                height={55}
                width={250}
              />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="ml-auto my-auto  lg:mr-6 flex items-center ">
          {/* unlogged options */}
          <button
            type="button"
            className={`hidden md:block whitespace-nowrap mr-5  text-sm md:text-md sm:text-md ${
              !startNavAnimations ? 'text-primary-400' : 'text-white'
            } font-semibold flex`}
            onClick={() => {
              if (scrollToSection) {
                scrollToSection(1);
              }
            }}
          >
            Descarga la app
          </button>

          <button
            type="button"
            className={`text-sm md:text-md sm:text-md md:px-10 py-2 px-4 rounded w-full font-semibold tracking-wide hover:opacity-80 ${
              !startNavAnimations
                ? ' bg-primary-400 text-white'
                : ' bg-white text-primary-400'
            }  font-semibold`}
            onClick={() => setSignInModalIsOpen(true)}
          >
            Ingresa
          </button>
        </div>
      </nav>
      {startNavAnimations ? (
        <hr className="absolute w-full mt-20 md:mt-24  z-20 opacity-20" />
      ) : null}
      <SignInModal
        isOpen={signInModalIsOpen}
        setIsOpen={setSignInModalIsOpen}
        setSignUpModalIsOpen={setSignUpModalIsOpen}
        setResetPasswordModalIsOpen={setResetPasswordModalIsOpen}
      />
      <SignUpModal
        isOpen={signUpModalIsOpen}
        setIsOpen={setSignUpModalIsOpen}
        setSignInModalIsOpen={setSignInModalIsOpen}
      />
      <ResetPasswordModal
        isOpen={resetPasswordModalIsOpen}
        setIsOpen={setResetPasswordModalIsOpen}
        setSignUpModalIsOpen={setSignUpModalIsOpen}
        setSignInModalIsOpen={setSignInModalIsOpen}
      />
      <ChangePasswordModal
        isOpen={changePasswordModalIsOpen}
        setIsOpen={setChangePasswordModalIsOpen}
        setSignInModalIsOpen={setSignInModalIsOpen}
      />
    </>
  );
}

export default Nav;
