import React from 'react';
import { IsoType } from '@spa-cars/ui';
import CardsStack from '../CardStack';
import byMyCar from '../../../public/images/byMyCar-service.png';

const values = [
  {
    illustration: byMyCar.src,
    title: 'CONSTRUIR CONFIANZA',
    description:
      'La confianza es la clave para lograr procesos sostenibles y fiables. La confianza significa creer. Creer en el talento interno y proyectar seguridad hacia afuera. Construir confianza en nuestros grupos de interés.',
  },
  {
    illustration: byMyCar.src,
    title: 'LIDERAZGO TRANSFORMADOR',
    description:
      'Nos inspira tener un liderazgo venezolano, que nos define desde ¨ser, el saber y el hacer¨. Somos líderes que buscan un cambio, una mejor calidad de vida para todos.',
  },
  {
    illustration: byMyCar.src,
    title: 'SER EL CAMBIO POSITIVO',
    description:
      'Nos esforzaremos por ser los mejores, por cambiar la cultura y establecer mejores prácticas para lograr un impacto positivo para todos.  El foco es la calidad total de lo que hacemos.',
  },
  {
    illustration: byMyCar.src,
    title: 'HACER QUE SUCEDA',
    description:
      'Actuamos con compromiso, con los más altos estándares de integridad y responsabilidad, porque nos enorgullece lo que hacemos, por nuestro trabajo, nuestros clientes y nuestra comunidad.',
  },
];

function Values() {
  return (
    <section id="services" className="w-full ">
      <div className="max-w-[1390px] m-auto px-5 md:px-10 xl:px-5  flex flex-wrap ">
        <div className="relative w-full md:w-1/2  md:min-h-screen h-full flex flex-col items-center justify-center order-2 md:order-1">
          <IsoType className="opacity-10 absolute z-0 mx-auto my-auto left-0 right-0 mt-10 w-[20rem] h-[18rem] md:w-[40rem] md:h-[35rem]" />
          <CardsStack cards={values} name="Valores Spacars" />
        </div>
        <div className="w-full md:w-1/2 h-full flex flex-col justify-center py-10 lg:py-0 md:min-h-screen order-1 md:order-2 ">
          <div className="w-full lg:w-3/4">
            <p className="text-text-black text-2xl md:text-4xl font-semibold ">
              Valores Spacars
            </p>
            <p className="my-10 text-text-black">
              Nuestros valores son la base de nuestro negocio y dejan su huella
              por nuestra forma de trabajar.
            </p>
            <p className="text-primary-400 text-xl md:text-2xl font-semibold ">
              Misión Spacars
            </p>
            <p className="my-10 text-text-black">
              Brindar una nueva experiencia de calidad, en servicio de
              mantenimiento automotriz, mediante el uso de una APP tecnológica y
              de cabinas ecológicas.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Values;
