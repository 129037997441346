import React from 'react';
import { motion } from 'framer-motion';
import Point from './Point';
import car3 from '../../../../public/images/car-3.png';

interface CarViewProps {
  currentCarView: number;
  setCurrentCarView: React.Dispatch<React.SetStateAction<number>>;
  currentPoint: number;
  setCurrentPoint: React.Dispatch<React.SetStateAction<number>>;
  carCheckpoints: {
    title: string;
    description?: string;
  }[];
}

function CarView3({
  currentCarView,
  currentPoint,
  setCurrentCarView,
  setCurrentPoint,
  carCheckpoints,
}: CarViewProps) {
  return (
    <motion.div
      animate={{
        opacity: [0, 1],
        transition: {
          duration: 0.5,
        },
      }}
    >
      <img
        src={car3.src}
        alt="car"
        className="w-[15rem] md:w-[40rem] mx-auto "
      />
      <Point
        hrClassName="rotate-[-40deg] w-20 md:w-36 right-[1rem] top-[1.5rem] md:right-[7rem] md:top-[5rem] md:right-[7rem]"
        pointClassName="right-[5px] top-[-8px] md:right-[108px] md:top-[28px]"
        title={carCheckpoints[7].title}
        active={currentPoint === 7}
        onClick={() => {
          setCurrentPoint(7);
        }}
      />
      <Point
        hrClassName="rotate-[50deg] w-20 md:w-48 left-[2rem] top-[1rem] md:left-[7rem] md:top-[3.2rem] md:right-[7rem]"
        pointClassName="left-[40px] top-[-20px] md:left-[142px] md:top-[-27px]"
        title={carCheckpoints[8].title}
        active={currentPoint === 8}
        onClick={() => {
          setCurrentPoint(8);
        }}
      />
      <Point
        hrClassName="rotate-[-20deg] w-14 md:w-[230px] left-[-40px] top-[80px] md:left-[-182px] md:top-[200px]"
        pointClassName="left-[-45px] top-[85px] md:left-[-180px] md:top-[235px]"
        title={carCheckpoints[9].title}
        active={currentPoint === 9}
        onClick={() => {
          setCurrentPoint(9);
        }}
      />
    </motion.div>
  );
}

export default CarView3;
