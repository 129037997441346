import React from 'react';
import { Footer } from '../shared';
import Intro from './Intro/Intro';
import Nav from './Nav';
import Experience from './Experience/Experience';
import CarCheckpoints from './CarCheckpoints/CarCheckpoints';
import DownloadApp from './DownloadApp';
import History from './History/History';
import AboutUs from './AboutUs/About';
import Values from './Values/Values';
import Allies from './Allies/Allies';

const sections = [
  'intro',
  'experience',
  'carCheckpoints',
  'history',
  'aboutUs',
  'values',
  'allies',
  'downloadApp',
];

function Landing() {
  const [startNavAnimations, setStartNavAnimations] = React.useState(false);

  const sectionRefs = sections.reduce((acc: any, value, idx) => {
    acc[idx] = React.createRef<HTMLDivElement>();
    return acc;
  }, {});

  // scroll to a section
  const handleScrollToSection = (idx: number) =>
    sectionRefs[idx].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  return (
    <>
      <Nav
        startNavAnimations={startNavAnimations}
        scrollToSection={handleScrollToSection}
      />
      <main className="bg-white">
        <section ref={sectionRefs[0]}>
          <Intro setStartNavAnimations={setStartNavAnimations} />
        </section>
        <section ref={sectionRefs[1]}>
          <Experience />
        </section>
        <section ref={sectionRefs[2]}>
          <CarCheckpoints />
        </section>
        <section ref={sectionRefs[3]}>
          <History />
        </section>
        <section ref={sectionRefs[4]}>
          <AboutUs />
        </section>
        <section ref={sectionRefs[5]}>
          <Values />
        </section>
        <section ref={sectionRefs[6]}>
          <Allies />
        </section>
        <section ref={sectionRefs[7]}>
          <DownloadApp />
        </section>
        <Footer scrollToSection={handleScrollToSection} />
      </main>
    </>
  );
}

export default Landing;
