export const experienceSteps = [
  {
    name: 'Agenda tu cita',
    preDescription:
      '¡No tienes tiempo para hacer el mantenimiento de tu carro, con Spacars puedes organizarte!',
    selectedDescription:
      'En 3 pasos reserva tu cita de 20 min para realizar el cambio de aceite y filtro:',
    bullets: [
      'Descarga app Spacars y registra tu carro',
      'Agenda tu cita',
      'Realiza el pago con precio justo del mercado y con marcas certificadas',
    ],
  },
  {
    name: 'No olvides más realizar cambio de aceite',
    preDescription:
      '¡Se te olvida cuando realizaste el último cambio de aceite, tranquilo Spacars te recordará cuándo te corresponde tu próximo servicio! ',
    selectedDescription:
      ' Recibirás notificaciones en nuestra app Spacars de próximos servicios de acuerdo a un medidor de kilometraje. Comenzando este recordatorio 200 kilómetros antes de cumplir límite para realizar el servicio.',
    bullets: [],
  },
  {
    name: 'No encuentras un taller de confianza',
    preDescription:
      '¡No encuentras un taller de confianza para hacerle servicio a tu carro, Spacars te trae la solución con la grabación y registro de fotos de todo el proceso! ',
    selectedDescription:
      'Contarás con un historial de cada visita a Spacars donde podrás ver el video y fotos de todos los puntos de revisión del servicio.  Esto estará disponible desde la app Spacars y en la página web.',
    bullets: [],
  },
  {
    name: 'Confort',
    preDescription:
      '¡No te sientes en confort cuando esperas el servicio a tu carro, en Spacars tendrás un lugar ameno para tu corta visita!',
    selectedDescription: '',
    bullets: [
      'Cómoda sala de estar construida con materiales reusados, UpCycling',
      'Sustentable y segura para intemperie y áreas techadas',
      'Ambiente limpio y con contenedores para el reciclaje de materiales y aceites',
    ],
  },
  {
    name: 'Precios al alcanze de tu mano',
    preDescription:
      '¡Desconoces los precios del servicio, en Spacars desde la comodidad de tu casa podrás verlo!',
    selectedDescription:
      'Al seleccionar el servicio en la app o página web Spacars podrás ver los precios según cada tipo de aceite',
    bullets: [],
  },
];
