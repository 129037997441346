import React from 'react';
import { motion } from 'framer-motion';
import renderCar from '../../../public/images/car.png';

const containerVariant = {
  animate: {
    // backgroundColor: ['#3ABCB0', '#ffffff', '#3ABCB0'],
    transition: {
      duration: 0.5,
    },
  },
};

const titleContainerVariant = {
  animate: {
    opacity: [0, 1],
    transition: {
      duration: 1.5,
    },
  },
};

const carVariant = {
  animate: {
    scale: 3,
    transition: {
      duration: 1,
      delay: 1.5,
    },
  },
};

const topTextVariant = {
  initial: {
    marginBottom: '5rem',
  },
  animate: {
    marginBottom: '8rem',
    transition: {
      duration: 1,
      delay: 1.5,
    },
  },
};

const bottomTextVariant = {
  initial: {
    marginTop: '5rem',
  },
  animate: {
    marginTop: '8rem',
    transition: {
      duration: 1,
      delay: 1.5,
    },
  },
};

function CarTitle() {
  return (
    <motion.div
      variants={containerVariant}
      animate="animate"
      className="absolute top-0 h-full mt-[6rem] pb-20 bg-transparent  w-full flex items-center justify-center"
    >
      <motion.div
        className="relative"
        variants={titleContainerVariant}
        animate="animate"
      >
        <motion.h2
          variants={topTextVariant}
          animate="animate"
          initial="initial"
          className="uppercase text-4xl md:text-8xl font-semibold text-white text-center "
        >
          Creando el
        </motion.h2>
        <motion.img
          className="absolute z-10 left-0 right-0 top-0 bottom-0 mx-auto my-auto h-14 w-28 md:h-auto md:w-[10rem]"
          src={renderCar.src}
          alt="Car"
          variants={carVariant}
          animate="animate"
        />
        <motion.h2
          variants={bottomTextVariant}
          animate="animate"
          initial="initial"
          className="uppercase text-4xl md:text-8xl font-semibold text-white text-center mt-20"
        >
          futuro juntos
        </motion.h2>
      </motion.div>
    </motion.div>
  );
}

export default CarTitle;
