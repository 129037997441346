import React from 'react';
import { motion } from 'framer-motion';
import Point from './Point';
import car1 from '../../../../public/images/car-1.png';

interface CarViewProps {
  currentCarView: number;
  setCurrentCarView: React.Dispatch<React.SetStateAction<number>>;
  currentPoint: number;
  setCurrentPoint: React.Dispatch<React.SetStateAction<number>>;
  carCheckpoints: {
    title: string;
    description?: string;
  }[];
}

// Este componente no se podia reutilizar, por alguna razon de buggueban los points sobre el carro
function CarView1({
  currentCarView,
  currentPoint,
  setCurrentCarView,
  setCurrentPoint,
  carCheckpoints,
}: CarViewProps) {
  return (
    <motion.div
      animate={{
        opacity: [0, 1],
        transition: {
          duration: 0.5,
        },
      }}
    >
      <img
        src={car1.src}
        alt="car"
        className="w-[15rem] md:w-[40rem] mx-auto "
      />
      {/* <Point
        hrClassName="rotate-[-40deg] w-20 md:w-36 right-[0px] top-[24px] md:right-[112px] md:top-[56px] md:right-[112px]"
        pointClassName="right-[-10px] top-[-8px] md:right-[108px] md:top-[3px]"
        title={carCheckpoints[0].title}
        active={currentPoint === 0}
        onClick={() => {
          setCurrentPoint(0);
        }}
      /> */}
      <Point
        hrClassName="rotate-[40deg] w-[80px] top-[16px] left-[40px] md:left-[152px] md:w-[220px] md:top-[48px] md:left-[152px]"
        pointClassName="top-[-16px] left-[40px] md:left-[171.2px] md:top-[-30px]"
        title={carCheckpoints[0].title}
        active={currentPoint === 0}
        onClick={() => {
          setCurrentPoint(0);
        }}
      />
      <Point
        hrClassName="w-[60px] top-[72px] right-[-8px] md:right-[-32px]  md:w-[140px] md:top-[192px] md:right-[32px] rotate-[190deg] "
        pointClassName="top-[72.6px] right-[-25.6px]  md:right-[12.8px] md:top-[198px]"
        title={carCheckpoints[1].title}
        active={currentPoint === 1}
        onClick={() => {
          setCurrentPoint(1);
        }}
      />
      <Point
        hrClassName="rotate-[140deg] w-[100px] bottom-[32px] left-[64px] md:left-[160px] md:w-[300px] md:top-[296px] md:left-[160px]"
        pointClassName="left-[68.8px] top-[134.4px] md:left-[188.8px] md:top-[390px]"
        title={carCheckpoints[2].title}
        active={currentPoint === 2}
        onClick={() => {
          setCurrentPoint(2);
        }}
      />
    </motion.div>
  );
}

export default CarView1;
