/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

interface titlesProps {
  titles: {
    title: string;
    description: string;
    year: string;
  }[];
  activeTitle: number;
  setActiveTitle: React.Dispatch<React.SetStateAction<number>>;
}

function Titles({ titles, activeTitle, setActiveTitle }: titlesProps) {
  return (
    <div className="hidden      absolute h-full  left-0 top-0 bottom-0 my-auto right-0 md:flex  items-center mb-[10rem]">
      <div className="w-36 flex flex-col">
        {titles.map((title, i) => (
          <div
            className={`py-1 relative z-30 cursor-pointer flex flex-col ${
              activeTitle === i ? 'w-full' : 'w-3/4'
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTitle(i);
            }}
            key={`${title.title}-${i}`}
          >
            <span
              className={` text-lg ${
                activeTitle === i
                  ? 'text-primary-300 font-bold ml-auto'
                  : 'text-neutral-100 ml-auto'
              } `}
            >
              {title.title}
            </span>
            <hr
              className={`${
                activeTitle === i
                  ? 'bg-primary-300  border-primary-300 border-[1px] '
                  : 'border-neutral-100 bg-neutral-100 border-[0.5px] '
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Titles;
