import { motion } from 'framer-motion';
import React from 'react';
import renderCar from '../../public/images/render-car.png';
import mockup from '../../public/images/mockup.png';
import googlePlay from '../../public/images/google-play.png';
import appStore from '../../public/images/app-store.png';

const carVariant = {
  animate: {
    scale: 2,
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
};

function DownloadApp() {
  return (
    <section id="DownloadApp" className="w-full bg-white min-h-screen flex">
      <div className="max-w-[1390px] m-auto px-5 bg-white relative py-20">
        <motion.img
          className="absolute z-0 left-0 right-0 top-44 sm:top-0  sm:bottom-0 mx-auto sm:my-auto h-16 w-28 md:h-auto md:w-96 opacity-[0.15]"
          src={renderCar.src}
          alt="Car"
          variants={carVariant}
          whileInView="animate"
        />
        <div className="flex flex-no-wrap relative z-10 flex-col md:flex-row">
          <div className=" md:w-1/2 flex">
            <img
              src={mockup.src}
              alt="mockup"
              className="max-h-72 mr-[5%] md:max-h-96 mx-auto md:mx-0 md:ml-auto "
            />
          </div>
          <div className="w-full px-10 md:px-0 md:w-1/2 mx-auto flex-col">
            <span className=" text-text-black my-2">Descárgalo ahora</span>
            <h4 className="text-4xl text-primary-300 font-semibold my-2">
              Spacars App
            </h4>
            <span className="text-text-black text-base my-2 ">
              Porque sabemos que necesitas recuperar la credibilidad en los
              servicios. Estamos aquí para ofrecerte transparencia y confianza
              con el sello SPACARS.
            </span>
            <div className="flex gap-5 mt-5 ">
              {/* FIXME LINKS */}
              <a
                href="https://apps.apple.com/us/app/spacars/id6448402287?utm_source=spacars-web&utm_campaign=download-app"
                className="my-auto"
              >
                <img
                  alt="Disponible en App Store"
                  src="/images/app-store.svg"
                  className="h-12"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.avilatek.spacars&utm_source=spacars-web&utm_campaign=download-app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Disponible en Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/es-419_badge_web_generic.png"
                  className="h-16"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DownloadApp;
