/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { motion } from 'framer-motion';
import inside1 from '../../../public/images/interior.jpg';
import inside2 from '../../../public/images/interior-2.jpg';
import shop1 from '../../../public/images/shop-1.jpg';
import shop2 from '../../../public/images/shop-2.jpg';

const mosaicVariant = {
  initial: {
    zIndex: 20,
  },
  inView: {
    zIndex: 10,
    opacity: [0, 1],
    transition: {
      duration: 2,
    },
  },
};

interface MosaicProps {
  activeTitle: number;
  setActiveTitle: React.Dispatch<React.SetStateAction<number>>;
  title: {
    title: string;
    description: string;
    year: string;
  };
  titles: {
    title: string;
    description: string;
    year: string;
  }[];
}

function Mosaic({ activeTitle, setActiveTitle, title, titles }: MosaicProps) {
  return (
    <motion.div
      variants={mosaicVariant}
      whileInView="inView"
      className="relative z-10 flex flex-col flex-nowrap  sm:grid  md:grid-cols-24 gap-3 w-full md:w-3/4 mx-auto  pb-10 sm:pb-0 sm:h-[35rem]"
    >
      <div
        className={` ${
          activeTitle === 0 ? 'h-auto' : 'h-16'
        } order-1  sm:h-auto w-full  col-start-[2] row-start-[3] row-span-5 col-span-4  cursor-pointer  `}
        onClick={(e) => {
          e.preventDefault();
          setActiveTitle(0);
        }}
      >
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <div className="absolute bg-primary-400 opacity-90 h-full w-full z-10 rounded-lg" />
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={inside2.src}
          />
          <div className=" h-full relative z-20 flex items-center justify-center flex-col text-white p-5">
            <span>{titles[0].title}</span>
            {activeTitle === 0 ? (
              <span className="sm:hidden">{title.description}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="hidden sm:block sm:h-auto col-start-[6] row-start-[2]  col-span-7 row-span-6  ">
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={inside1.src}
          />
        </div>
      </div>
      <div
        className={`order-2 ${
          activeTitle === 1 ? 'h-auto' : 'h-16'
        }  sm:h-auto col-start-[13] row-start-[0]  col-span-7 row-span-3 cursor-pointer`}
        onClick={(e) => {
          e.preventDefault();
          setActiveTitle(1);
        }}
      >
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <div className="absolute bg-primary-400 opacity-90 h-full w-full z-10 rounded-lg" />
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={inside1.src}
          />
          <div className="h-full relative z-20 flex items-center justify-center flex-col text-white p-5">
            <span>{titles[1].title}</span>
            {activeTitle === 1 ? (
              <span className="sm:hidden">{title.description}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="hidden sm:block col-start-[13] row-start-[4]  col-span-5 row-span-12  ">
        <div className="h-full w-full relative  hover:scale-[1.05] transition-all ease-in">
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={shop1.src}
          />
        </div>
      </div>
      <div className="hidden sm:block col-start-[18] row-start-[4]  col-span-5 row-span-4  ">
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={shop2.src}
          />
        </div>
      </div>
      <div
        className={`${
          activeTitle === 2 ? 'h-auto' : 'h-16'
        }  order-2 sm:h-auto col-start-[18] row-start-[8]  col-span-7 row-span-8 cursor-pointer  `}
        onClick={(e) => {
          e.preventDefault();
          setActiveTitle(2);
        }}
      >
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <div className=" absolute bg-primary-400 opacity-90 h-full w-full z-10 rounded-lg" />
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={inside2.src}
          />
          <div className=" h-full relative z-20 flex items-center justify-center flex-col text-white p-5">
            <span>{titles[2].title}</span>

            {activeTitle === 2 ? (
              <span className="sm:hidden">{title.description}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="hidden sm:block col-start-[0] row-start-[8]  col-span-6 row-span-9 ">
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={shop1.src}
          />
        </div>
      </div>
      <div
        className={`${
          activeTitle === 3 ? 'h-auto' : 'h-16'
        } order-2  sm:h-auto col-start-[7] row-start-[8]  col-span-6 row-span-8 cursor-pointer`}
        onClick={(e) => {
          e.preventDefault();
          setActiveTitle(3);
        }}
      >
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <div className="absolute bg-primary-400 opacity-90 h-full w-full z-10 rounded-lg" />
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={inside2.src}
          />
          <div className=" h-full relative z-20 flex items-center justify-center flex-col text-white p-5">
            <span>{titles[3].title}</span>

            {activeTitle === 3 ? (
              <span className="sm:hidden">{title.description}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="hidden sm:block col-start-[7] row-start-[16]  col-span-5 row-span-6  ">
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={shop2.src}
          />
        </div>
      </div>
      <div
        className={`${
          activeTitle === 4 ? 'h-auto' : 'h-16'
        } order-3  sm:h-auto col-start-[12] row-start-[16]  col-span-4 row-span-6 cursor-pointer`}
        onClick={(e) => {
          e.preventDefault();
          setActiveTitle(4);
        }}
      >
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <div className="absolute bg-primary-400 opacity-90 h-full w-full z-10 rounded-lg" />
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={inside2.src}
          />
          <div className=" h-full relative z-20 flex items-center justify-center flex-col text-white p-5 cursor-pointer">
            <span>{titles[4].title}</span>

            {activeTitle === 4 ? (
              <span className="sm:hidden">{title.description}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="hidden sm:block col-start-[16] row-start-[16]  col-span-6 row-span-4  ">
        <div className="h-full w-full relative hover:scale-[1.05] transition-all ease-in">
          <img
            className="h-full w-full object-cover rounded-lg absolute "
            alt=""
            src={inside2.src}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default Mosaic;
