import React from 'react';
import { motion } from 'framer-motion';

interface ServiceCardProps {
  illustration: any;
  title: string;
  description: string;
  intro: string;
  cardClass?: string;
  idx?: number;
  rotate?: () => void;
}

interface ServiceCardProps {
  illustration: any;
  title: string;
  description: string;
  cardClass?: string;
  idx?: number;
  rotate?: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function AboutCard({
  illustration,
  title,
  description,
  cardClass,
  idx,
  rotate,
  intro,
}: ServiceCardProps) {
  const variants = [
    { rotate: [1, 0, 1] },
    { rotate: [8, 2, 8] },
    { rotate: [-6, -12, -6] },
  ];

  return (
    <motion.div
      animate={variants[idx]}
      transition={{ duration: 3, repeat: Infinity }}
      className={`flip-card w-64 h-[21rem] sm:w-72 sm:h-96 absolute transition-all duration-300 ease-in-out ${cardClass}`}
    >
      {/* Card inner */}
      <div className="flip-card-inner relative w-full h-full">
        {/* Front card */}
        <div
          className={`flip-card-front w-full h-full overflow-hidden absolute rounded-xl  transition-all duration-300 ease-in-out ${
            idx === 0 ? 'bg-primary-400 shadow-xl' : 'bg-[#E9FEF8] shadow-lg'
          }`}
        >
          <div className="w-full h-full flex flex-col px-6  justify-center pb-3 ">
            <p className="text-text-white font-semibold tracking-wide  mr-auto text-base lg:text-lg mt-4 ">
              {title}
            </p>
            <img src={illustration} alt={title} className=" mx-auto" />
            <span className=" font-light text-text-white text-xs sm:text-sm ">
              {intro}
            </span>
          </div>
        </div>
        {/* Back card */}
        <button
          type="button"
          onClick={rotate}
          aria-label="Cambiar de servicio"
          className="flip-card-back w-full h-full text-start overflow-hidden focus:ring-0 focus:outline-none absolute top-0 left-0 shadow bg-white rounded-xl px-8 sm:px-10 py-4 sm:py-5"
        >
          <div className="w-full h-full flex flex-col ">
            <h2 className="text-lg lg:text-2xl font-bold text-primary-300 ">
              {title}
            </h2>
            <p className="text-xs lg:text-sm mt-5 text-text-black ">
              {description}
            </p>
          </div>
        </button>
      </div>
    </motion.div>
  );
}
