import React from 'react';
import AboutCard from './AboutCard';

export type Card = {
  illustration: any;
  title: string;
  description: string;
  intro?: string;
};

interface CardsStackProps {
  cards: Array<Card>;
  name: string;
}

export default function CardsStack({ cards: _cards, name }: CardsStackProps) {
  const [cards, setCards] = React.useState<Array<Card>>(_cards);
  const stackStyles = [
    'first-flip-card z-30',
    '-top-4 ml-4 z-20',
    '-top-8 -left-5 z-10',
  ];

  const rotate = () => {
    const { length } = cards;
    setCards((prev) => prev.map((_, idx) => prev[(idx + 1 + length) % length]));
  };

  return (
    <>
      <div className="relative w-full lg:w-64 h-80 mt-10 md:mt-0 flex justify-center">
        {cards.map(({ illustration, title, description, intro }, idx) => (
          <AboutCard
            key={title}
            illustration={illustration}
            title={title}
            description={description}
            cardClass={stackStyles[idx]}
            idx={idx}
            rotate={rotate}
            intro={intro}
          />
        ))}
      </div>
      <div className="relative z-10 mt-8 sm:mt-20 w-full  flex justify-center text-dark-blue dark:text-medium-gray gap-24">
        <p className="font-semibold text-primary-500 text-xl">{name}</p>
      </div>
      <div className="relative z-10 mt-5 mb-10 md:mb-0 w-full  flex justify-center text-dark-blue dark:text-medium-gray gap-24">
        {/* <div className="text-medium-blue flex items-center  dark:text-dark-gray duration-150 transition-all ease-in-out cursor-not-allowed">
          <span className="text-primary-300">Prev</span>
          <hr className="w-10 bg-primary-300 h-[2px] ml-3" />
        </div> */}
        <button
          type="button"
          className="focus:ring-0 flex items-center focus:outline-none text-primary-300 hover:text-primary-500 duration-150 transition-all ease-in-out"
          onClick={rotate}
          aria-label="Avanzar en servicios"
        >
          <hr className="w-10 bg-primary-300 h-[2px] mr-3" /> Next
        </button>
      </div>
    </>
  );
}
