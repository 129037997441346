import React from 'react';

interface CheckpointButtonProps {
  idx: number;
  setCurrentPoint: React.Dispatch<React.SetStateAction<number>>;
  isCurrent?: boolean;
}

export default function CheckpointButton({
  idx,
  setCurrentPoint,
  isCurrent,
}: CheckpointButtonProps) {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        setCurrentPoint(idx);
      }}
      aria-label={`checkpoint-button-${idx}`}
      className="relative w-6 my-2"
    >
      <div
        className={`z-10 ${
          isCurrent ? 'scale-[1.4] bg-secondary-200' : 'bg-primary-200'
        } w-2 h-2 rounded-full`}
      />
      <div
        className={`top-[-4px] left-[-4px] absolute z-0  ${
          isCurrent ? 'scale-[1.4] bg-secondary-200' : 'bg-primary-200'
        } w-4 h-4 rounded-full  opacity-50 `}
      />
      <span className="sr-only">Checkpoint button {idx}</span>
    </button>
  );
}
