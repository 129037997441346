import React from 'react';
import test from '../../../public/images/shop-1.jpg';

interface StepCardProps {
  step: {
    name: string;
    preDescription: string;
    selectedDescription: string;
    bullets: string[];
  };
  index: number;
  active: boolean;
}

function StepCard({ step, index, active }: StepCardProps) {
  return (
    <div className="pr-16 md:px-10 ">
      <div
        className={`cursor-pointer relative z-20 w-[20rem] h-[27rem] rounded-xl shadow-lg bg-white flex  justify-center  ${
          index % 2 === 0 && !active && 'rotate-3'
        } ${index % 2 !== 0 && !active && '-rotate-3'}`}
      >
        <img
          src={test.src}
          alt=""
          className="absolute h-full object-cover rounded-xl"
        />
        {active ? (
          <>
            {' '}
            <div className="absolute w-full h-full bg-primary-500  rounded-xl" />
            <div className="relative z-10 flex flex-col text-center items-center py-10 px-5">
              <span className="mb-5 text-text-white font-semibold text-base sm:text-lg">
                {index + 1}. {step.name}
              </span>
              <span className="text-text-white  text-sm sm:text-base ">
                {step.selectedDescription}
              </span>
              <div className="my-3 text-start">
                {step.bullets.map((item) => (
                  <div className="flex">
                    <div className="mr-2">
                      <div className=" mt-2 bg-white rounded-full w-2 h-2" />
                    </div>
                    <span className="text-text-white text-sm sm:text-base flex w-11/12">
                      {item}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            {' '}
            <div className="absolute w-full h-full bg-primary-500 opacity-60 rounded-xl " />
            <div className="relative z-10  flex-col text-center  py-10 px-5 flex items-center justify-center">
              <span className="text-text-white  text-sm sm:text-base font-semibold ">
                {step.preDescription}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default StepCard;
