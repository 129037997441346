import React from 'react';
import { IsoType } from '@spa-cars/ui';
import CardsStack from '../CardStack';
import byMyCar from '../../../public/images/byMyCar-service.png';

const pillars = [
  {
    illustration: byMyCar.src,
    title: 'Calidad para el cliente',
    intro:
      'Tenemos un propósito claro: elevar la calidad de vida del venezolano',
    description:
      'Nos enfocamos en lograr calidad total para el cliente y el aliado comercial: satisfacemos sus necesidades, le ofrecemos una buena experiencia, le aseguramos agilidad en la resolución de problemas y nos comprometemos a la mejora continua en los procesos.',
  },
  {
    illustration: byMyCar.src,
    title: 'Tecnología e innovación',
    intro:
      'Creemos que la tecnología abre puertas, acerca a personas y facilita los procesos.',
    description:
      'La innovación es cambio. La innovación es flexibilidad en el proceso de ensayo, prueba y error para lograr transformar lo existente y mejorarlo. La innovación exitosa permite a las personas y sociedades disfrutar de un mayor bienestar.',
  },
  {
    illustration: byMyCar.src,
    title: 'Confianza y Transparencia',
    intro:
      'El propósito de construir confianza como sustento fundamental de  nuestras operaciones.',
    description:
      'Sabemos que la confianza se sostiene con buenas relaciones, hechos y con transparencia. Creemos que desde la transparencia fomentamos la credibilidad en nosotros: nuestros productos, servicios, procedimientos y resultados. Estamos aquí para ofrecerte transparencia y confianza con el sello SPACARS',
  },
  {
    illustration: byMyCar.src,
    title: 'Operaciones sustentables',
    intro:
      'Queremos aportar valor social, ambiental y económico entre nuestros stakehorders',
    description:
      'Queremos llevar a cabo un modelo de gestión triple impacto. Nos comprometemos a disminuir el impacto socio-ambiental. Queremos cambiar la cultura, por lo que decidimos empoderar a los conductores para mantener sus vehículos de manera sostenible en el tiempo.',
  },
];

function AboutUs() {
  return (
    <section id="services" className="w-full bg-[#F5FCFB]">
      <div className="max-w-[1390px] m-auto px-5 md:px-10 xl:px-5 flex flex-wrap ">
        <div className="w-full md:w-1/2 h-full flex flex-col justify-center py-10 lg:py-0 md:min-h-screen">
          <div className="w-full lg:w-3/4">
            <p className="text-text-black text-2xl md:text-4xl font-semibold ">
              ¿Quiénes somos?
            </p>
            <p className="my-10 text-text-black">
              Spacars es una compañía especializada en la ejecución de servicios
              de mantenimiento preventivo a vehículos automotores, apalancados
              en tecnología e innovación y con impacto positivo en el medio
              ambiente.
            </p>
            <p className="text-primary-400 text-xl md:text-2xl font-semibold ">
              Visión Spacars
            </p>
            <p className="my-10 text-text-black">
              Trascender en la industria automotriz, cambiando la cultura de
              servicio de mantenimiento de vehículos e incidir en una calidad de
              vida integral.
            </p>
          </div>
        </div>
        <div className="relative w-full md:w-1/2  md:min-h-screen overflow-hidden h-full flex flex-col items-center justify-center">
          <IsoType className="opacity-10 absolute z-0 mx-auto my-auto left-0 right-0 mt-10 w-[20rem] h-[18rem] md:w-[40rem] md:h-[35rem]" />
          <CardsStack cards={pillars} name="Pilares de Spacars" />
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
