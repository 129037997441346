import React from 'react';
import { AnimationControls, motion } from 'framer-motion';

const lineVariant = {
  initial: {
    backgroundColor: '#ffffff',
  },
  visible: {
    backgroundColor: ['#ffffff', '#3ABCB0'],
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
};

interface LineProps {
  setStartNavAnimations: React.Dispatch<React.SetStateAction<boolean>>;
  lineControls: AnimationControls;
}

function Line({ setStartNavAnimations, lineControls }: LineProps) {
  return (
    <motion.div
      variants={lineVariant}
      initial="initial"
      whileInView="visible"
      animate={lineControls}
      onAnimationComplete={() => {
        setStartNavAnimations(true);
      }}
      className="absolute mx-auto my-auto left-0 right-0 w-full h-16 bg-primary-400 flex justify-center items-center"
    >
      <h1 className="uppercase text-2xl md:text-5xl font-semibold text-white">
        Innovando, tranformando
      </h1>
    </motion.div>
  );
}

export default Line;
