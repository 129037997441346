import React from 'react';
import { carCheckpoints } from '../../../lib/carCheckpoints';
import CarView1 from './CarViews/CarView1';
import CarView2 from './CarViews/CarView2';
import CarView3 from './CarViews/CarView3';
import CheckpointButton from './CheckpointButton';

export default function Car() {
  const [currentCarView, setCurrentCarView] = React.useState(0);
  const [currentPoint, setCurrentPoint] = React.useState(0);
  const prevScrollY = React.useRef(0);
  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      // if car is in viewport
      if (containerRef.current.getClientRects()[0].top - 400 > 0) {
        return;
      }
      if (currentScrollY - prevScrollY.current > 20) {
        prevScrollY.current = currentScrollY;
        if (currentPoint < 9) {
          setCurrentPoint(currentPoint + 1);
        }
      }
      if (currentScrollY - prevScrollY.current < -20) {
        prevScrollY.current = currentScrollY;
        if (currentPoint > 0) {
          setCurrentPoint(currentPoint - 1);
        }
      }
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentPoint]);

  React.useEffect(() => {
    if (currentPoint >= 0 && currentPoint < 3) {
      setCurrentCarView(0);
    } else if (currentPoint >= 3 && currentPoint <= 6) {
      setCurrentCarView(1);
    } else if (currentPoint >= 7) {
      setCurrentCarView(2);
    }
  }, [currentPoint]);

  return (
    <div className="w-full flex mt-16 flex-col xl:flex-row lg:min-h-[80vh] relative">
      {' '}
      <div className="w-full px-10 lg:px-0  xl:w-3/12">
        <p className="mt-10 text-text-black  text-lg tracking-wide leading-7">
          Además de cambio y filtro de aceite te haremos la revisión de 10
          puntos y en caso de requerir te agregaremos fluidos para que se
          encuentren en el nivel óptimo. Además los expertos te darán las
          recomendaciones en cuanto a la revisión de tu carro.
        </p>
      </div>
      <div
        ref={containerRef}
        className="w-full xl:w-9/12 flex relative z-10 pt-20 pb-20 lg:-ml-14"
      >
        <div className="mx-auto relative">
          {currentCarView === 0 ? (
            <CarView1
              currentCarView={currentCarView}
              setCurrentCarView={setCurrentCarView}
              currentPoint={currentPoint}
              setCurrentPoint={setCurrentPoint}
              carCheckpoints={carCheckpoints}
            />
          ) : null}
          {currentCarView === 1 ? (
            <CarView2
              currentCarView={currentCarView}
              setCurrentCarView={setCurrentCarView}
              currentPoint={currentPoint}
              setCurrentPoint={setCurrentPoint}
              carCheckpoints={carCheckpoints}
            />
          ) : null}

          {currentCarView === 2 ? (
            <CarView3
              currentCarView={currentCarView}
              setCurrentCarView={setCurrentCarView}
              currentPoint={currentPoint}
              setCurrentPoint={setCurrentPoint}
              carCheckpoints={carCheckpoints}
            />
          ) : null}
        </div>
      </div>
      <div className="absolute w-full bottom-0 lg:hidden flex justify-center py-15 md:py-0">
        <span className="text-base md:text-xl font-semibold text-primary-400">
          {carCheckpoints[currentPoint].title}
        </span>
      </div>
      <div className="absolute ml-2 bottom-[-30px] md:right-0 h-full w-full justify-center flex items-end md:flex-col md:justify-center cursor-pointer z-30">
        {carCheckpoints.map((_, idx) => (
          <CheckpointButton
            key={`car-side-button-${idx}`}
            idx={idx}
            setCurrentPoint={setCurrentPoint}
            isCurrent={idx === currentPoint}
          />
        ))}
      </div>
    </div>
  );
}
