import React from 'react';
import { IsoType } from '@spa-cars/ui';
import logo from '../../../public/images/logo.png';
import AlliesSlider from './AlliesSlider';

function Allies() {
  return (
    <div className="w-full bg-[#F5FCFB] min-h-[70vh] overflow-hidden flex flex-col  ">
      <IsoType className="opacity-10 absolute z-10 mx-auto my-auto left-0 right-0 mt-10 w-[20rem] h-[18rem] md:w-[30rem] md:h-auto" />
      <div className="max-w-[1390px] mx-auto px-5 py-10 ">
        <div className="my-auto flex justify-center items-center overflow-hidden">
          <h3 className="text-text-black text-2xl md:text-4xl font-semibold text-center  ">
            Los aliados{' '}
          </h3>
          <img
            className="w-44 md:w-52 h-auto mt-1 ml-5 z-0"
            src={logo.src}
            alt="logo"
          />
          {/* water mark */}
        </div>
      </div>
      <div className="relative z-50 rounded-full flex h-40 mt-16 mx-auto w-10/12 overflow-hidden">
        <AlliesSlider />
      </div>
    </div>
  );
}

export default Allies;
