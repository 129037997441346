/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  Button,
  LargeArrowIcon,
  useRefDimensions,
  useScreenSize,
} from '@spa-cars/ui';
import StepCard from './StepCard';

interface StepsCarousel {
  steps: {
    name: string;
    preDescription: string;
    selectedDescription: string;
    bullets: string[];
  }[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

function StepsCarousel({ steps, activeStep, setActiveStep }: StepsCarousel) {
  const carousel = React.useRef<HTMLDivElement>(null);

  // carousel cards refs
  const refs = steps.reduce((acc: any, value, index) => {
    acc[index] = React.createRef<HTMLDivElement>();
    return acc;
  }, {});

  const { screenSize } = useScreenSize();

  const { width: cardWidth } = useRefDimensions(refs[0]);

  const scroll = (index: number) => {
    if (index === steps.length) {
      setActiveStep(0);
      carousel?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      carousel?.current?.scrollTo({
        top: 0,
        left: cardWidth * index,
        behavior: 'smooth',
      });
    }
  };

  React.useEffect(() => {
    scroll(activeStep);
  }, [activeStep]);

  return (
    <>
      <div className="absolute flex gap-5 mt-4 -bottom-5 md:bottom-auto  md:top-[-80px] md:right-10 md:left-auto mx-auto my-auto left-0 right-0  justify-center ">
        <Button
          type="button"
          className="w-10 px-2 py-1"
          aria-label="Arrow left"
          onClick={(e) => {
            e.preventDefault();
            if (activeStep !== 0) {
              setActiveStep(activeStep - 1);
            }
          }}
        >
          <span className="sr-only">Arrow left</span>
          <LargeArrowIcon className="w-6 h-6 text-white" />
        </Button>
        <Button
          type="button"
          className="w-10 px-2 py-1"
          aria-label="Arrow right"
          onClick={(e) => {
            e.preventDefault();
            if (activeStep === steps.length) {
              setActiveStep(0);
            } else if (activeStep < steps.length) {
              setActiveStep(activeStep + 1);
            }
          }}
        >
          <span className="sr-only">Arrow right</span>
          <LargeArrowIcon className="w-6 h-6 text-white rotate-180" />
        </Button>
      </div>
      <div className="w-full py-5 md:py-10 order-2 md:order-1">
        <div
          ref={carousel}
          className="hide-scrollbar flex relative overflow-x-scroll z-0 items-center mx-auto w-full py-3"
          style={{
            width: `${screenSize > 640 ? 'auto' : cardWidth - 50}px`,
          }}
        >
          <div className="flex justify-center">
            {steps.map((item, i) => (
              <div
                ref={refs[i]}
                key={`car-${i}`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveStep(i);
                }}
              >
                <StepCard step={item} index={i} active={i === activeStep} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default StepsCarousel;
