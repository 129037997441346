export const carCheckpoints = [
  {
    title: 'Estado del motor',
  },
  {
    title: 'Aceite de dirección Hidráulica',
  },
  {
    title: 'Cambio de aceite \n(con Flush previo si se requiere)',
  },
  {
    title: 'Liga de Clutch',
  },
  {
    title: 'Liga de freno',
  },
  {
    title: 'Fluido limpia-parabrisas',
  },
  {
    title: 'Presión y \nestado de Cauchos',
  },
  {
    title: 'Cambio de filtro',
  },
  {
    title: 'Refrigerante',
  },
  {
    title: 'Luces externas delanteras y traseras',
  },
];
