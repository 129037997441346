import React from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import logo from '../../../public/images/logo-white.png';
import Line from './Line';
import CarTitle from './CarTitle';

const lineVariantDisappear = {
  animate: {
    opacity: [1, 0],
    zIndex: 0,
    transition: {
      duration: 1.5,
      delay: 0,
    },
  },
};

const introVariant = {
  animate: {
    backgroundColor: ['#ffffff', '#3ABCB0'],
    transition: {
      duration: 1,
      delay: 1.5,
    },
  },
};

interface IntroProps {
  setStartNavAnimations: React.Dispatch<React.SetStateAction<boolean>>;
}

function Intro({ setStartNavAnimations }: IntroProps) {
  const [showCarTitle, setShowCarTitle] = React.useState(false);
  const lineControls = useAnimationControls();

  return (
    <motion.div
      variants={introVariant}
      initial="initial"
      animate="animate"
      className="relative w-full h-screen flex items-center"
      onAnimationComplete={() => {
        lineControls.start(lineVariantDisappear.animate);
        setShowCarTitle(true);
      }}
      id="intro"
    >
      {/* water mark */}
      <img
        src={logo.src}
        alt="logo"
        className="opacity-10 absolute z-10 mx-auto my-auto left-0 right-0 mt-10 w-[20rem] h-[18rem] md:w-[40rem] md:h-[35rem]"
      />
      {/* introduction phrase line */}
      <Line
        setStartNavAnimations={setStartNavAnimations}
        lineControls={lineControls}
      />
      {showCarTitle ? <CarTitle /> : null}
    </motion.div>
  );
}

export default Intro;
