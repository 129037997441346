import React from 'react';
import { motion } from 'framer-motion';
import Point from './Point';
import car2 from '../../../../public/images/car-2.png';

interface CarViewProps {
  currentCarView: number;
  setCurrentCarView: React.Dispatch<React.SetStateAction<number>>;
  currentPoint: number;
  setCurrentPoint: React.Dispatch<React.SetStateAction<number>>;
  carCheckpoints: {
    title: string;
    description?: string;
  }[];
}

function CarView2({
  currentCarView,
  currentPoint,
  setCurrentCarView,
  setCurrentPoint,
  carCheckpoints,
}: CarViewProps) {
  return (
    <motion.div
      animate={{
        opacity: [0, 1],
        transition: {
          duration: 0.5,
        },
      }}
    >
      <img
        src={car2.src}
        alt="car"
        className="w-[15rem] md:w-[40rem] mx-auto "
      />
      <Point
        hrClassName="rotate-[-40deg] w-20 md:w-36 right-[0px] top-[24px] md:right-[112px] md:top-[80px] md:right-[112px]"
        pointClassName="right-[-10px] top-[-8px] md:right-[108px] md:top-[28px]"
        title={carCheckpoints[3].title}
        active={currentPoint === 3}
        onClick={() => {
          setCurrentPoint(3);
        }}
      />
      <Point
        hrClassName="rotate-[60deg] w-20 md:w-56 left-[128px] top-[112px] md:left-[320px] md:top-[312px] md:right-[112px]"
        pointClassName="left-[185.6px] top-[140px] md:left-[480px] md:top-[400px]"
        title={carCheckpoints[4].title}
        active={currentPoint === 4}
        onClick={() => {
          setCurrentPoint(4);
        }}
      />
      <Point
        hrClassName="rotate-[50deg] w-20 md:w-48 left-[32px] top-[16px] md:left-[112px] md:top-[51.2px] md:right-[112px]"
        pointClassName="left-[40px] top-[-20px] md:left-[142px] md:top-[-27px]"
        title={carCheckpoints[5].title}
        active={currentPoint === 5}
        onClick={() => {
          setCurrentPoint(5);
        }}
      />
      <Point
        hrClassName="rotate-[-20deg] w-14 md:w-[200px] left-[0px] top-[128px] md:left-[-75px] md:top-[330.8px] md:right-[0px]"
        pointClassName="left-[-8px] top-[132.8px] md:left-[-70px] md:top-[360.2px]"
        title={carCheckpoints[6].title}
        active={currentPoint === 6}
        onClick={() => {
          setCurrentPoint(6);
        }}
      />
    </motion.div>
  );
}

export default CarView2;
