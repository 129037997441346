import React from 'react';
import { IsoType } from '@spa-cars/ui';
import Car from './Car';

function CarCheckpoints() {
  return (
    <div id="carCheckpoints" className="w-full bg-[#F5FCFB]">
      <div className="max-w-[1390px] m-auto px-5 md:px-10  py-10 ">
        <div className="my-auto">
          <h3 className="text-text-black text-2xl md:text-4xl font-semibold ">
            Spacars es la respuesta a la rapidez que necesitas.
          </h3>
          {/* water mark */}
          <IsoType className="opacity-10 absolute z-10 mx-auto my-auto left-0 right-0 mt-10 w-[20rem] h-[18rem] md:w-[40rem] md:h-[35rem]" />
          <Car />
        </div>
      </div>
    </div>
  );
}

export default CarCheckpoints;
