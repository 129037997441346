import React from 'react';

const logos = [
  {
    src: 'images/castrol.png',
    size: 'w-32 md:w-36 xl:w-40 mb-1',
  },
  {
    src: 'images/ultralub.png',
    size: 'w-48 md:w-52',
  },
  {
    src: 'images/inca.png',
    size: 'w-28 md:w-32',
  },
  {
    src: 'images/millard.png',
    size: 'w-28 md:w-56',
  },
  {
    src: 'images/motul.png',
    size: 'w-28 md:w-32',
  },
  {
    src: 'images/venoco.png',
    size: 'w-28 md:w-32',
  },
  {
    src: 'images/liquimoly.png',
    size: 'w-28 md:w-32',
  },
];

function AlliesSlider() {
  const [allies, setAllies] = React.useState([
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
    ...logos,
  ]);
  return (
    <div className="slide-track flex items-center gap-32">
      {allies.map(({ src, size }, i) => (
        <img
          key={`${src}-${i}`}
          src={src}
          alt={src}
          className={`duration-200 transition-all ease-in-out ${
            size ?? 'w-36 xl:w-40 '
          }`}
        />
      ))}
    </div>
  );
}

export default AlliesSlider;
