/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

interface PointProps {
  hrClassName: string;
  pointClassName: string;
  active: boolean;
  title: string;
  onClick: () => void;
}

function Point({
  hrClassName,
  pointClassName,
  active,
  title,
  onClick,
}: PointProps) {
  return (
    <div
      className="absolute h-full w-full top-0 left-0"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <hr
        className={`absolute z-10   border-[1.2px] ${
          active
            ? 'border-secondary-200 bg-secondary-200'
            : 'border-primary-200 bg-primary-200'
        }    ${hrClassName}`}
      />
      <div className={`absolute ${pointClassName} flex cursor-pointer z-30`}>
        <div className="relative w-6">
          <div
            className={`z-10 ${
              active ? 'scale-[1.4] bg-secondary-200' : 'bg-primary-200'
            } w-3 h-3 rounded-full  `}
          />
          <div
            className={`top-[-5.5px] left-[-6px] absolute z-0  ${
              active ? 'scale-[1.4] bg-secondary-200' : 'bg-primary-200'
            } w-6 h-6 rounded-full  opacity-50 `}
          />
        </div>
        <div
          className={`absolute top-[-7px] left-4 flex w-44 ${
            active ? 'text-neutral-300' : 'text-neutral-100'
          }  hidden lg:block text-center `}
        >
          <span className="mb-2 text-xs md:text-base text-center">{title}</span>
        </div>
      </div>
    </div>
  );
}

export default Point;
