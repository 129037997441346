import React from 'react';

interface StepsProps {
  steps: {
    name: string;
    preDescription: string;
    selectedDescription: string;
    bullets: string[];
  }[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

function Steps({ steps, activeStep, setActiveStep }: StepsProps) {
  return (
    <div className="mt-32 ml-5 flex flex-col">
      {steps.map((item, i) => (
        <div key={item.name} className="flex items-center my-2 cursor-pointer ">
          {activeStep === i ? (
            <hr className="w-10 border-[1px] bg-primary-300 border-primary-300 mr-5" />
          ) : null}
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setActiveStep(i);
            }}
            className={`font-semibold text-lg text-left ${
              activeStep === i ? 'text-primary-300' : 'text-neutral-100'
            } `}
          >
            {item.name}
          </button>
        </div>
      ))}
    </div>
  );
}

export default Steps;
